.plug-container {
    padding: 40px 80px 0;

    .plug-header {
        color: #172b4d;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    .plug-description {
        color: #172b4d;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        a {
            text-decoration: none;
            color: #427dff;
            font-weight: 700;
        }
    }
}